@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Atkinson';
	src: url('../public/fonts/atkinson-regular.woff') format('woff');
	font-weight: 400; 
	font-style: normal;
	font-display: swap;
}

body {
  scroll-behavior: smooth;
  background: rgb(40, 40, 71);
  font-family: 'Atkinson', sans-serif;
  color: white;
}

li {
    margin-left: 10px;
    list-style-type: circle;
 }
